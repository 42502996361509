import React, {forwardRef, useImperativeHandle, useRef} from "react";
import {ButtonBase, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  buttonOpacity: {
    // display: 'flex',
    // flexDirection: 'column',
    '&:hover': {
      opacity: 0.8,
      // transform: 'scale(1.01)',
    },
    '&.Mui-disabled': {
      opacity: 0.3,
    },
    '&:focus': {
      opacity: 0.5,
      // transform: 'scale(.95)'
    },
  },
  buttonOpacityDisabled: {
    // display: 'flex',
    // flexDirection: 'column',
    opacity: 0.3,
    // '&:hover': {
    //   opacity: 0.3,
    //   transform: 'scale(1.01)',
    // },
    // '&.Mui-disabled': {
    //   opacity: 0.3,
    // },
    // '&:focus': {
    //   opacity: 0.5,
    //   transform: 'scale(.95)'
    // },
  },
}));

const TouchableOpacity = React.memo(forwardRef((props, ref) => {

  const propsOnClick = props.onClick;

  const classes = useStyles();

  const refButton = useRef();

  useImperativeHandle(ref, () => ({
    click, getBoundingClientRect, dispatchEvent
  }));

  const click = () => {
    if (refButton.current) {
      refButton.current.click();
    }
  };

  const dispatchEvent = (event) => {
    if (refButton.current) {
      refButton.current.dispatchEvent(event);
    }
  };

  const getBoundingClientRect = () => {
    if (refButton.current) {
      return refButton.current.getBoundingClientRect();
    }
    return {};
  };

  const onClick = (event) => {
    propsOnClick(event);
    if (refButton.current) {
      refButton.current.blur();
    }
  };

  // const propsExcept = cloneDeep(props);
  // delete propsExcept.classes;
  // delete propsExcept.onClick;

  return (
    // <div {...props} className={props.disabled ? classes.buttonOpacityDisabled : classes.buttonOpacity} onClick={props.disabled ? null : props.onClick}>{props.children}</div>
    <ButtonBase disableRipple ref={refButton} {...props} onClick={onClick} className={classes.buttonOpacity}>{{...props}.children}</ButtonBase>
  );
}))

export default TouchableOpacity;
